import React, { forwardRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import { ToastContainer, toast } from "react-toastify";
import {
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";
import { TextField } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Container, Button, Card } from "react-bootstrap";
import axios from "../Components/Axios/Axios";
// Custom Components Import
import Loader from "../Components/Spinner/Spinner";
import Sidebar from "../Components/Sidebar/MainSidebar/Sidebar";
import requests from "../Components/Requests/Requests";
import { InputAdornment} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";

const useStyles = makeStyles(() => ({
  noBorder : {
    border: "none",
    borderRadius: '5px',
  },
}));

function AllBlogs() {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0); // MaterialTable uses 0-based page indexing
  const [totalCount, setTotalCount] = useState(0); // Total count of entries
  const [searchValue, setSearchValue] = useState("");

  const tableIcons = {
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight  {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  useEffect(() => {
    // Fetch data whenever the page changes
    if (searchValue === '') {
      fetchData(page + 1); // Fetch data for the current page
    } else {
      fetchDataSearch(searchValue, page + 1); // Fetch search data for the current page
    }
  }, [page]); // Triggered when page changes

  const fetchData = async (currentPage) => {
    setLoading(true);
    try {
      const response = await axios.get(requests.allUsers, {
        params: { page: currentPage }, // Pass 1-based page number to the server
      });
      if (response.data.Success === 1) {
        setData(response.data.data); // Set new data
        setTotalCount(response.data.totalCount); // Set total count
      } else {
        toast.warning(`Something went wrong`, {
          position: "top-right",
          autoClose: 2500,
        });
      }
    } catch (err) {
      toast.warning(err.message, {
        position: "top-right",
        autoClose: 2500,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchDataSearch = async (searchValue, currentPage) => {
    setLoading(true);
    try {
      const response = await axios.get(requests.filterUsers, {
        params: { page: currentPage, search: searchValue }, // Pass 1-based page number and search term to the server
      });
      if (response.data.Success === 1) {
        setData(response.data.data); // Set new data
        setTotalCount(response.data.totalCount); // Set total count
      } else {
        toast.warning(`Something went wrong`, {
          position: "top-right",
          autoClose: 2500,
        });
      }
    } catch (err) {
      toast.warning(err.message, {
        position: "top-right",
        autoClose: 2500,
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage); // Update current page
  };

  const handleSearch = () => {
    // Trigger the search API call when the button is clicked
    setPage(0); // Reset to the first page
    // fetchDataSearch(searchValue, 1); // Fetch search results for the first page
    if (searchValue === '') {
      fetchData(1); // Fetch data for the current page
    } else {
      fetchDataSearch(searchValue, 1); // Fetch search data for the current page
    }
  };

  return (
    <>
      <Sidebar />
      <Container className="content">
        <ToastContainer
          position="top-right"
          autoClose={10000}
          hideProgressBar={false}
        />
        <Card className="card">
          <Card.Header className="heading">
            <div
              style={{ display: "flex", justifyContent: "space-between",alignItems:"ce" }}
              className="inside-header"
            >
              All Users
              <div className="searchbarUser">
                <TextField
                  variant="outlined"
                  placeholder="Search..."
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                  // style={{ marginRight: "0px",    padding: "0px"}}
                  // sx={{
                  //   "& .MuiInputBase-input": {
                  //     overflow: "hidden",
                  //     textOverflow: "ellipsis",

                  //   }
                  // }}

      autoFocus
      classes={{notchedOutline:classes.input}}

      // onChange={handlePhoneNumberChange}
      className={classes.textField}
    
      InputProps={{
        
        classes:{notchedOutline:classes.noBorder}
      }}
                  style={{
                    marginRight: "0px",
                    padding: "0px",
                    // maxWidth: "200px", // Set maximum width as desired
                    // overflow: "hidden",
                    // textOverflow: "ellipsis",
                    // whiteSpace: "nowrap",
                    border: "1px solid #d2d2d2",
                  }}
                />
                <Button onClick={handleSearch} variant="primary">
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19"><g id="Group_40" data-name="Group 40" transform="translate(-0.001)"><g id="layer1_00000072963618680574873660000015406673454742146451_" transform="translate(0.001)"><g id="g18115"><path id="path17034" d="M9.743,292.644A7.748,7.748,0,1,0,14.5,306.5l4.869,4.869a.95.95,0,0,0,1.343-1.343l-4.869-4.869a7.739,7.739,0,0,0-6.1-12.51Zm0,1.9a5.849,5.849,0,1,1-5.849,5.849A5.835,5.835,0,0,1,9.743,294.544Z" transform="translate(-1.995 -292.644)" fill="#B95BD0"></path></g></g></g></svg>
                </Button>
              </div>
            </div>
          </Card.Header>
          <Card.Body className="body">
            {loading ? (
              <Loader margin={"auto"} />
            ) : (
              <MaterialTable
                data={data} // Data to be displayed in the table
                icons={tableIcons}
                columns={[
                  { title: "#", field: "id", type: "numeric" },
                  { title: "credits", field: "credits", type: "numeric" },
                  { title: "premium_credits", field: "premium_credits" },
                  { title: "email", field: "email" },
                  {
                    title: "Edit Credits",
                    render: (rowData) => (
                      <Link to={{ pathname: "/editCredits", state: rowData }}>
                        <Button variant="primary" size="md">
                          Edit
                        </Button>
                      </Link>
                    ),
                  },
                ]}
                options={{
                  pageSize: 30, // Set default rows per page to 30
                  paginationType: "normal", // Use a stepped pagination style
                  showTitle: false,
                  pagination: true,
                  pageSizeOptions: false, // Disable page size selector
                  tableLayout: "auto",
                  search:false
                }}
                totalCount={totalCount} // Total number of rows (from backend)
                page={page} // Current page number (state)
                onChangePage={(newPage) => handlePageChange(newPage)} // Handle page change event
                localization={{
                  pagination: {
                    labelRowsSelect: "rows",
                    labelDisplayedRows: "{from}-{to} of {count}",
                  },
                }} 
              />
            )}
          </Card.Body>
        </Card>
      </Container>
    </>
  );
}

export default AllBlogs;
